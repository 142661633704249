import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import $ from 'jquery';

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import { WiTime9 } from "react-icons/wi";
import { FaMapMarkerAlt, FaMobileAlt } from "react-icons/fa"
//import Recaptcha from 'react-recaptcha'

export class ContactPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      g_recaptcha_response: '',
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      message: ''
    }
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  handleMainChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleMainSubmit = e => {
    $.ajax({
      url: "https://71jmri3wtj.execute-api.us-west-2.amazonaws.com/production/",
      type: "POST",
      dataType: "JSON",
      data: JSON.stringify({
        ReplyTo: this.state.email,
        Name: this.state.firstName + " " + this.state.lastName,
        Company: this.state.company,
        Comments: this.state.message,
        Subject: "New submission from Contact Us - Page",
        Gotcha: this.state.verifyAddress,
        GoogleRecaptchaResponse: "klmkwelkm2-mk2ml2389u4nkfejhijfrw0"
      }),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        window.location.href = "/thanks";
      },
      error: function (jqXhr, textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
    e.preventDefault();
  }

  verifyCallback = response => {
    this.setState({ g_recaptcha_response: response });
  };

  render() {
    const { seo, hero } = this.props
    return (
      <div>
        <SEO title={seo.title} description={seo.description} />
        <HeroOther data={hero} />
        <section className="section">
          <div className="container">
            <div className="contact">
              <div className="columns">
                <div className="column is-3">
                  <span className="label">
                    <span className="icon">
                      <WiTime9 />
                    </span>
                    <span>
                      Hours:
                      <div className="small-text">Schedule a consultation</div>
                      <div className="small-text"> with us today!</div>
                    </span>
                  </span>
                  <span className="label">
                    <span className="icon">
                      <FaMobileAlt />
                    </span>
                    <span>
                      Phone:
                      <div className="small-text">1+(720)-588-8133</div>
                    </span>
                  </span>
                  <span className="label">
                    <span className="icon">
                      <FaMapMarkerAlt />
                    </span>
                    <span>
                      Address:
                      <div className="small-text">14143 Denver West Pkwy</div>
                      <div classname="small-text">Suite 100</div>
                      <div className="small-text">Golden, CO 80401</div>
                    </span>
                  </span>
                </div>
                <div className="column is-8">
                  <p className="has-text">WE WOULD LOVE TO TALK TO YOU ABOUT ANY QUESTIONS YOU MIGHT HAVE.</p>
                  <form
                    name="contact"
                    id="contact"
                    onSubmit={this.handleMainSubmit}
                  >
                    <div className="field">
                      <div className="columns">
                        <div className="column is-6">
                          <label htmlFor={'firstName'}>
                            First name
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'text'}
                              name={'firstName'}
                              onChange={this.handleMainChange}
                              id={'firstName'}
                              required={true}
                              placeholder="Your First Name"
                            />
                          </div>
                        </div>
                        <div className="column is-6">
                          <label htmlFor={'lastName'}>
                            Last name
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'text'}
                              name={'lastName'}
                              onChange={this.handleMainChange}
                              id={'lastName'}
                              required={true}
                              placeholder="Your Last Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <div className="columns">
                        <div className="column is-6">
                          <label htmlFor={'company'}>
                            Company
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'text'}
                              name={'company'}
                              onChange={this.handleMainChange}
                              id={'company'}
                              required={true}
                              placeholder="Your Company Name"
                            />
                          </div>
                        </div>
                        <div className="column is-6">
                          <label htmlFor={'email'}>
                            Email Address
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'email'}
                              name={'email'}
                              onChange={this.handleMainChange}
                              id={'email'}
                              required={true}
                              placeholder="Your Email Address"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor={'message'}>
                        Message
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={'message'}
                          onChange={this.handleMainChange}
                          id={'message'}
                          required={true}
                          placeholder="Message..."
                        />
                      </div>
                    </div>
                    <div className="field">
                      <button type="submit" style={{ background: "#53c5d1" }} >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div >
    )
  }
}

ContactPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <ContactPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    })
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
